function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementsByTagName("header")[0].style.padding = "0.3vw";
      document.getElementsByTagName("header")[0].style.position = "fixed";
      //document.getElementById("logo").style.fontSize = "25px";
    } else {
      document.getElementsByTagName("header")[0].style.padding = "1.5vw";
      document.getElementsByTagName("header")[0].style.position = "fixed";
      // document.getElementsByTagName("header")[0].style.fontSize = "1.2vw";
    }
  }
  
  window.onscroll = function() {scrollFunction();};
  