import '../styles/index.scss';
import './global.js';

let folders = [
  ['2019', 14, 'chinese_new_year_2019_body'],
  ['2018', 35, 'chinese_new_year_2018_body'],
  ['sanya_2017', 11, 'sanya_2016_body'],
  ['mid_autumn_2017', 10, 'mid_autumn_2017_body'],
  ['2016', 21, 'chinese_new_year_2016_body'],
  ['2015', 31, 'chinese_new_year_2015_body'],
];

let count = 0;

importAll(require.context('../assets/committee', false, /\.(png|jpe?g|svg)$/));

function importAll (r) {
  r.keys().forEach(r);
}

function imageloop(i, f_dir_name, max_photos, appendTo){
  let fileextension = ".jpg"; // image format
  let dir = "./src/assets/" + f_dir_name + '/'; // folder location
  const filePath = dir + i + fileextension;

  $("<img loading='lazy' />").attr('src', filePath).appendTo('.' + appendTo);
  if (i == max_photos){
    // coll[0].nextElementSibling.style.maxHeight = coll[0].nextElementSibling.scrollHeight + "vw";

    // // Show the 2019 gallery on initial load first
    // if (f_dir_name === '2019'){
    //   content_boxes[0].style.maxHeight = "100%";
    // }
  }
  else{
    i++;
    count++;
    imageloop(i, f_dir_name, max_photos, appendTo); 
  };
}; 

function loadImages() {
  // Hack to import all images cause webpack doesn't recognise it in imageLoop()
  importAll(require.context('../assets/', false, /\.(png|jpe?g|svg)$/));
  importAll(require.context('../assets/2019', false, /\.(png|jpe?g|svg)$/));
  importAll(require.context('../assets/2018', false, /\.(png|jpe?g|svg)$/));
  importAll(require.context('../assets/sanya_2017', false, /\.(png|jpe?g|svg)$/));
  importAll(require.context('../assets/mid_autumn_2017', false, /\.(png|jpe?g|svg)$/));
  importAll(require.context('../assets/2016', false, /\.(png|jpe?g|svg)$/));
  importAll(require.context('../assets/2015', false, /\.(png|jpe?g|svg)$/));

  for (let folder of folders){
    const f_dir_name = folder[0];

    const max_photos = folder[1];
    const appendTo = folder[2];
    let i = 1;

    imageloop(i, f_dir_name, max_photos, appendTo); 
  }
}; 

var coll = document.getElementsByClassName("collapsible");
var i;
let content;


for (i = 0; i < coll.length; i++) {
  coll[i].addEventListener("click", function() {
    loadImages();

    this.classList.toggle("active");
    let content = this.nextElementSibling;

    if (content.style.maxHeight){
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "vw";

        // Determine which type this collapsiable was under
        // Recalculate overall content box size
        if (this.classList.contains("s")){
          content_boxes[1].style.maxHeight = content_boxes[1].scrollHeight + "vw";
        }
        else if (this.classList.contains("d")){
          content_boxes[2].style.maxHeight = content_boxes[2].scrollHeight + "vw";
        }
        else{
          content_boxes[0].style.maxHeight = "10000vw";
        }
      } 

  });
}

let child_thumbnails = document.getElementsByClassName("child");
let triangle_wedges = document.getElementsByClassName("wedges");
let content_boxes = document.getElementsByClassName("content_box");

var e;

// By default set gallery as active
if (child_thumbnails.length > 0){
  child_thumbnails[0].classList.add("active");
}

let triangle_wedges_pos = "0vw";
let background_color;
let triangle_wedge;

for (e = 0; e < child_thumbnails.length; e++) {
    // Used so we can refer to which content box is clicked on
    child_thumbnails[e].index = e;

    child_thumbnails[e].addEventListener("click", function() {
        // Remove all other active toggles first
        for (e = 0; e < child_thumbnails.length; e++) {
            child_thumbnails[e].classList.remove("active");
        }

        // This activates the opacity on the thumbnail
        this.classList.add("active");

        // Wedge calculation
        switch (this.index){
          case 0: { // Gallery
            triangle_wedges_pos = "14.3vw";
            background_color = "rgba(255, 156, 51, 1)";
            break;
          }

          case 1: { // Sanya
            triangle_wedges_pos = "48.3vw";
            background_color = "rgba(255, 29, 37, 0.8)";
            break;
          }

          case 2: { // Donate
            triangle_wedges_pos = "81.3vw";
            background_color = "rgba(40, 128, 185, 1)";
            break;
          }

          default:
          {
            triangle_wedges_pos = 0; 
            background_color = "rgb(0,0,0)";
            break;
          }
        }

        // Move the wedges
        for (let e = 0; e < triangle_wedges.length; e++) {
          triangle_wedge = triangle_wedges[e];
          triangle_wedge.style.marginLeft = triangle_wedges_pos;
        }

        // Hide/unload all content first
        for (let e = 0; e < content_boxes.length; e++) {
          content = content_boxes[e];
          if (content.style.maxHeight){
            content.style.maxHeight = null;
          }

        // Show/load the selected content
        content_boxes[this.index].style.maxHeight = content_boxes[this.index].scrollHeight + "px";
        content_boxes[this.index].style.backgroundColor = background_color;

        // Color the bottom wedge
        triangle_wedges[1].style.backgroundColor = background_color;
      }
    });
  }
  
// Also show gallery content first
if (content_boxes.length > 0){  
  content_boxes[0].style.maxHeight = "10000vh";
}
